.horizontal-nav {
  display: flex;

  @media screen and (max-width: 991px){
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: white;
    left: 0;
    top: 0;
    z-index: 2;
    padding: 50px 30px 0;
  }
}

.horizontal-nav__item {
  padding: 0 20px;
  font-size: 20px;
  color: #43425d;
  border-right: 2px solid #43425d;
  transition: color 0.2s;

  &:hover {
    color: #e72f88;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: 0;
  }

  @media screen and (max-width: 991px){
    font-size: 16px;
    padding: 0 5px;
  }
}
