.list {
  display: flex;
  max-width: 1040px;
  width: 100%;
  margin: 30px 0;
}

.list-item {
  cursor: pointer;
  position: relative;
  width: 41%;
  min-width: 220px;
  text-decoration: none!important;
  padding-left: 30px;
}

.list-item.active .list-item-title {
  color: #e72f88;
}

.list-item.active::before {
  background: #e72f88;
}

.list-item:before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 18px;
  height: 18px;
  border: 3px solid #e72f88;
  border-radius: 50%;
}

.list-item-title {
  font-size: 20px;
  font-family: Roboto-Regular, sans-serif;
  color: #43425d;
  transition: color 0.2s;

  &.is-active {
    color: #e72f88;
  }
}

.list-item-title:hover {
  color: #e72f88;
}

@media screen and (max-width: 991px){
  .list-item-title {
    font-size: 16px;
    padding-left: 24px;
  }

  .list-item {
    margin-bottom: 10px;
    padding-left: 5px;
    min-width: 45% !important;
    /*margin-right: 30px;*/
  }

  .list-item:before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    width: 14px;
    height: 14px;
    border: 3px solid #e72f88;
    border-radius: 50%;
  }
}
