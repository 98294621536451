.container {
  width: 100%;
  max-width: calc(1440px);
  margin: 0 auto;
  padding: 0 20px;

  @media screen and (min-width: 1600px) {
    max-width: 1640px;
  }
}

.user-header {
  font-family: OpenSans, sans-serif;

  .btn {
    position: absolute;
    background: #e72f88 !important;
    border: 0;
    padding-right: 30px;
    color: #fff !important;
  }
}

.user-header__top {
  background-color: #c7c7c7;
  display: flex;
  padding: 14px 0 20px;

  @media screen and (max-width: 769px) {
    padding: 10px 0;
  }

  .btn {
    top: 10px;
    right: -16px;

    @media screen and (max-width: 769px) {
      font-size: 10px;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 769px) {
      padding: 0 12px;
      padding-right: 80px;
    }

    @media screen and (min-width: 770px) {
      width: calc(100vw - 100px);
      max-width: 1700px;
      padding-right: 50px;
    }
  }
}

.user-header__profile-image {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
  border: 0;
  background: black;

  @media screen and (max-width: 1440px) {
    font-size: 10px;
    //margin-right: 80px;
  }
}

.user-header__middle {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 600px;
  padding: 103px 0;
  position: relative;
  text-align: center;

  @media screen and (max-width: 769px) {
    max-height: 170px;
  }
}

.user-header__button-link {
  width: 231px;
  font-family: OpenSans;
  letter-spacing: 0.3px;
  height: 45px;
  transition: all 0.2s;

  @media screen and (max-width: 769px) {
    display: none;
  }

  &:hover {
    transform: translateX(-15px);
  }

  &:first-child {
    top: 70%;
    background-color: #c7c7c7 !important;
    right: -16px;
  }

  &:nth-child(2) {
    top: calc(70% + 45px);
    background-color: #e30072;
    right: -16px;
    a {
      color: #ebf4f7;
    }
  }

  &:nth-child(3) {
    top: calc(70% + 90px);
    right: -16px;
    background-color: #7c7c7b !important;
  }
}

.user-header__title {
  color: #fff;
  font-size: 61px;
  font-weight: 800;
  font-family: OpenSans;

  @media screen and (max-width: 769px) {
    font-size: 18px;
  }
}

.user-header__subtitle {
  color: #e72f88;
  font-size: 50px;
  font-family: OpenSans;
  font-weight: 800;

  @media screen and (max-width: 769px) {
    font-size: 18px;
  }
}

.user-header__bottom {
  padding: 90px 20px;
  text-align: center;
  background-color: #c7c7c7;

  @media screen and (max-width: 769px) {
    padding: 25px 10px;
  }
}

.user-header__nav {
  display: flex;
  justify-content: center;
  width: 100%;
}

.user-header__nav-item {
  margin: 0 22px;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  transition: color 0.2s;

  &:hover {
    text-decoration: none;
    color: #e72f88;
  }
}

.user-header__nav-link {
  color: #1d1d1d;
  font-size: '20px';
  letter-spacing: '0.4px';
  text-decoration: none;
  font-weight: bold;
  display: flex;
  justify-content: space-between;

  &:hover {
    color: #e72f88;
    text-decoration: none;
  }
}

.user-header__nav-link-active {
  color: #e11273;
  text-decoration: none;
}

.user-header__buchungsportal {
  color: #6e6f6e;
  font-size: '20px';
  letter-spacing: '0.4px';
  text-decoration: none;
  font-weight: bold;
  display: flex !important;
  align-items: center;

  &:hover {
    color: #e72f88;
    text-decoration: none;
  }
}

.user-header-container {
  padding: 30px 13%;
  @media screen and (max-width: 769px) {
    display: flex;
    justify-content: center;
  }
}

.user-header-logo {
  width: 280px;
  height: auto;
}

.user-header-nav {
  @media screen and (max-width: 769px) {
    flex-direction: column !important;
  }
}

.user-header-toggle {
  width: 48px;
  height: auto;
  margin-left: auto;
  .navbar-toggler-icon {
    width: 1em;
  }
}

.user-header-stack {
  @media screen and (max-width: 769px) {
    flex-direction: column-reverse !important;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 18px;
  height: 12px;
  right: 10px;
  top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #1d1d1d;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 30px;
  width: 30px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #1d1d1d;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 80px 50px 0;
  font-size: 18px;
}

/* Individual item */
.bm-item {
  padding-bottom: 15px;
}

.user-header__profile-dropdown-wrapper {
  display: inline;
}

.user-header__submenulist {
  list-style-type: none;
}

.user-header__submenubtn {
  display: flex !important;
  align-items: center;
}

.user-header__profile-chevron {
  font-size: 12px;
}
