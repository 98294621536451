.user-survey {
  margin: 8px 0 40px!important;

  .form-group {
    margin-bottom: 10px;
  }
}

.user-survey__title {
  font-size: 28px;
  font-weight: 400;
  color: #e72f88;
}

.user-survey__button {
  background: #e72f88!important;
  color: white!important;
  margin: 10px 0 30px;
  padding: 10px 26px;
  font-size: 16px;
  border: 0
}

.user-survey__answer {
  display: flex;
  margin-left: 16px;
  font-size: 16px;
}

.user-survey__question {
  font-size: 20px;
  color: #43425d;
  margin-top: 20px;
  font-weight: 400;
}
