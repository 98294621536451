.to-top-chevron {
  // color: #e11273;
  font-size: 16px;

  // &:hover {
  //   color: #fff;
  // }
}
.to-top-btn {
  opacity: 0.7;
  border-radius: 100% !important;
  position: fixed !important;
  bottom: 50vh;
  right: 50px;
  z-index: 999;
  width: 46px;
  height: 46px;
  color: #e11273 !important;
  border: solid 1px #e11273;

  &:hover {
    background-color: #e11273 !important;
    color: #fff !important;
    opacity: 1;
    border: solid 2px #e11273;
  }
  @media screen and (max-width: 769px) {
    right: 5px;
    bottom: 5px;
  }
}
