// General
html {
  scroll-behavior: smooth;
}

a,
button,
.btn {
  outline: none !important;
}

.app-container {
  display: flex;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  flex-direction: column;
  margin: 0;
}
