.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @media screen and (min-width: 1600px) {
    max-width: 1640px;
  }
}

.footer {
  font-family: OpenSans;
  text-align: center;

  .container {
    padding: 0;
  }
}

.footer__top {
  background-color: #fff;

  .container {
    padding: 20px 0 35px;
    position: relative;
  }
}

.footer__bottom {
  background-color: #c7c7c7;
  padding: 75px 0;
  color: #666666;

  @media screen and (max-width: 769px) {
    padding: 45px 0;
  }
}

.footer__partners {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 769px) {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.footer__partner {
  display: block;
  object-fit: contain;
  max-width: 12.5%;
  width: 150px;
  flex: 1;
  margin: 0 20px;

  @media screen and (max-width: 769px) {
    max-width: 100px;
    margin-bottom: 20px;
  }
}

.footer__social {
  position: absolute;
  bottom: 0;
  display: flex;
  transform: translateY(50%);
  right: 0;

  @media screen and (max-width: 769px) {
    right: 50%;
    transform: translate(50%, 50%);
  }
}

.footer__social-link {
  background-color: #e72f88;
  display: block;
  border-radius: 50%;
  font-size: 24px;
  margin-left: 10px;
  width: 45px;
  height: 45px;
  color: #fff;
  padding: 6px;

  @media screen and (max-width: 769px) {
    font-size: 17px;
    width: 36px;
    height: 36px;
  }
}

.footer__policy-links {
  display: flex;
  margin: 0 auto;
  width: max-content;

  @media screen and (max-width: 769px) {
    flex-direction: column;
    align-items: center;
  }
}

.footer__policy-link {
  margin: 0 20px;
  color: #666666;
  font-size: 24px;

  @media screen and (max-width: 769px) {
    font-size: 16px;
    margin-bottom: 14px;
  }
}

.footer__logo {
  width: 100%;
  max-width: 502px;
  height: 80px;
  object-fit: contain;
  margin-top: 60px;

  @media screen and (max-width: 769px) {
    margin-top: 0;
    max-width: 270px;
  }
}

.footer__contacts {
  margin-top: 60px;
  font-size: 24px;
  color: #666666;

  @media screen and (max-width: 769px) {
    display: none;
  }

  a {
    color: #666666;
    display: block;
  }
}

.footer_box {
  border: 1px solid #e11273;
  justify-content: space-around;
  padding: 50px 10px;
  @media screen and (max-width: 769px) {
    flex-direction: column !important;
    text-align: center;
  }
}

.footer_btn_group {
  max-width: 200px;
  @media screen and (max-width: 769px) {
    margin: auto;
  }
}

.footer_social {
  width: 64px;
  height: auto;
}

.footer_contact_line {
  margin-bottom: 0;
}

.footer_link:hover {
  text-decoration: none;
}
