.btn-bchange {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #e11273;
  --bs-btn-border-color: #e11273;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #b91563;
  --bs-btn-hover-border-color: #b91563;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #98245b;
  --bs-btn-active-border-color: #98245b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #bfc0c4;
  --bs-btn-disabled-border-color: #bfc0c4;
}

.btn-bchange-secondary {
  --bs-btn-color: #1d1d1d;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #e11273;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #e11273;
  --bs-btn-hover-border-color: #b91563;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #1d1d1d;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #98245b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #bfc0c4;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #bfc0c4;
}

.btn {
  // --bs-btn-padding-x: 0.75rem;
  // --bs-btn-padding-y: 0.375rem;
  // --bs-btn-font-family: ;
  // --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 700;
  // --bs-btn-line-height: 1.5;
  // --bs-btn-color: var(--bs-body-color);
  // --bs-btn-bg: transparent;
  // --bs-btn-border-width: var(--bs-border-width);
  // --bs-btn-border-color: transparent;
  // --bs-btn-border-radius: var(--bs-border-radius);
  // --bs-btn-hover-border-color: transparent;
  // --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  // --bs-btn-disabled-opacity: 0.65;
  // --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  // display: inline-block;
  // padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  // font-family: var(--bs-btn-font-family);
  // font-size: var(--bs-btn-font-size);
  // font-weight: var(--bs-btn-font-weight);
  // line-height: var(--bs-btn-line-height);
  // color: var(--bs-btn-color);
  // text-align: center;
  // text-decoration: none;
  // vertical-align: middle;
  // cursor: pointer;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // user-select: none;
  // border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: 35px;
  // background-color: var(--bs-btn-bg);
  // transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
