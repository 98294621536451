@font-face {
  font-family: 'Gotham Bold';
  src: url('./fonts/Gotham Bold.otf');
}
@font-face {
  font-family: 'Gotham Light';
  src: url('./fonts/Gotham Light.otf');
}
.d-preview {
  // display: flex;
  // align-items: flex-end;
}
.start-vid-btn {
  margin-top: 30px;
  height: 36px;
  width: 100%;
}
.d-swatch {
  height: 37px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}
.d-iqdh-logo {
  object-fit: contain;
  height: 50px;
  margin-top: -4px;
  margin-right: 25px;
}

.d-header {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.d-header-logo {
  object-fit: contain;
  height: 26px;
  z-index: 11;

  @media screen and (max-width: 769px) {
    max-width: 190px !important;
  }
}
.vid-controls {
  button {
    margin: 0 10px;
  }
  button:first-child {
    margin-left: 0;
  }
}
.d-react-select {
  .css-z5z6cw {
    background-color: #01b1ea !important;
  }
  .css-2o5izw {
    box-shadow: 0 0 0 0.2rem rgba(3, 177, 234, 0.7);
  }
}
.d-top-header-content-right {
  display: flex;
  align-items: flex-start;
}
.d-header-middle-content {
  display: flex;
  .d-header-subtitle {
    display: none;
  }
}
.modal-body-lg {
  z-index: 20000 !important;
}
.page-title-buttons {
  display: flex;
  button:last-child {
    margin-left: 15px;
  }
}
.chrome-picker {
  width: 100% !important;
}
.dropdown-menu {
  z-index: 10000;
}
.d-sidebar-nav-main img:not(.d-nav-active),
.d-sidebar-sub-nav img:not(.d-lg-icon) {
  max-width: 30px;
}
.d-lg-icon {
  max-width: 60px;
}

.d-range {
  position: relative;
  .divider {
    margin-top: 25px;
  }
}

.d-range-min {
  position: absolute;
  left: 0;
  top: 40px;
}

.d-range-max {
  position: absolute;
  right: 0;
  top: 40px;
}

.page-title-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.d-btn-discard {
  margin-left: 15px;
}

.d-menu-actions-link > button {
  margin-top: 15px;
}

.d-actions-link {
  text-decoration: none !important;
}

.d-table-filter {
  margin-bottom: 15px;
}

.d-table-filter-button {
  width: 100%;
  height: 100%;
}

.d-devices {
  max-width: 600px;
  .d-device {
    display: flex;
    padding: 10px;
    align-items: center;
    .d-device-label {
      padding-left: 10px;
    }
  }
}

.d-connect-tv {
  display: flex;
  align-items: center;
  button {
    margin-right: 15px;
  }
  .d-connect-indicator {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #19b775;
    margin-right: 15px;
  }
}

.d-loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  .block-ui {
    height: 100%;
  }
}

.d-checkbox-padding {
  padding-top: 25px;
}

.d-video-button {
  margin-top: 15px;
  margin-bottom: 15px;
}

.d-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: hidden;
  box-sizing: border-box;
  // overflow-x: hidden;
  font-family: 'Red Hat Display', sans-serif;

  .app-page-title {
    position: relative;
    margin: unset;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 15px;
  }
}

.d-content {
  width: 100%;
  padding-left: 15px;
  // padding-bottom: 100px;
  position: relative;
  margin: 0 auto;
  z-index: 100;
}
.d-content-padding {
  padding-right: 15px;
}

.d-content-main {
  padding-right: 15px;
  padding-left: 15px;
  width: 70%;
}

.d-content-body {
  display: flex;
  min-height: 100%;
  width: 100%;
  justify-content: space-between;
}

.d-content-right {
  padding-right: 15px;
  width: 30%;
}

.d-row {
  display: flex;
  & > div:not(.d-nomargin) {
    margin-right: 15px;
  }
}

.d-item-30 {
  width: 30%;
}

.d-item-40 {
  width: 40%;
}

.d-item-50 {
  width: 50%;
}

.d-content-inner {
  min-height: 100vh;
}

.d-header {
  // min-height: 200px;
  padding-top: 15px;
  font-family: 'Red Hat Display';
  margin-bottom: 10px;
}

.d-logo {
  position: fixed;
  width: 80%;
  left: 0;
}

.d-sidebar {
  position: absolute;
  min-width: 280px;
  width: 280px;
  height: 100vh;
  z-index: 900;
  color: #fff;
  left: 0;
  a {
    color: #fff !important;
    text-decoration: none !important;
  }
  .vertical-nav-menu .metismenu-container .metismenu-link.active {
    background: rgba(209, 243, 255, 0.27);
  }
}

.d-flex-row-center {
  display: flex;
  align-items: center;
  .card-title {
    margin-bottom: 0;
  }
}

.d-sidebar-base {
  background: rgba(85, 85, 85, 0.85);
  min-width: 280px;
  width: 280px;
  min-height: 100vh;
}

.d-menu-logo {
  width: 70%;
  height: 50px;
  object-fit: contain;
  position: absolute;
  right: 25px;
  top: 30px;
}

.d-widget {
  border: 4px solid;
  padding: 15px;
  margin-top: 15px;
  box-sizing: border-box;
}

.d-fullheight {
  height: calc(100% - 15px);
}

.d-footer {
  background: #ebeef3;
  min-height: 150px;
  padding-top: 20px;
  padding-bottom: 15px;
  color: #949b9d;
}

.d-widget-blue {
  border-color: #d13a7a;
}

.d-widget-green {
  border-color: #b4ca19;
}

.d-widget-grey {
  border-color: #dddddd;
}

.d-widget-red {
  border-color: #ffa025;
}

.d-widget-sea {
  border-color: #00b5ad;
}

.d-widget-greyblue {
  border-color: #91bfcc;
}

.d-widget-lightgreen.d-widget-solid {
  border-color: transparent;
  background: rgba(180, 202, 25, 0.43);
}

.d-widget-lightgrey.d-widget-solid {
  border-color: #ebeef3;
  background: #ebeef3;
}

.d-widget .list-group-item {
  background: transparent;
  border: none;
  padding-left: 0;
  padding-right: 0;
}
.d-widget-grey .list-group-item {
  padding-left: 15px;
}

.d-sidebar-content {
  position: absolute;
  right: 15px;
  top: 200px;
}

.d-header-top {
  display: flex;
  min-height: 70px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 15px;
  color: #cccccc;
}

.d-header-middle {
  display: flex;
  // height: 140px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.d-header-settings {
  margin-right: 70px;
  cursor: pointer;
}
.d-header-print,
.d-switch {
  cursor: pointer;
  margin-right: 30px;
  .react-switch-bg {
    height: 20px !important;
    width: 48px !important;
    div:first-child,
    div:nth-child(2) {
      display: flex;
      justify-content: center;
      padding-top: 3px;
    }
  }
  .react-switch-handle {
    width: 18px !important;
    height: 18px !important;
  }
}

.d-switch-text {
  color: #a8a7a7;
  margin-top: 3px;
  margin-left: 5px;
}
.d-logout-text {
  margin-top: 3px;
}

.d-header-middle-content,
.d-header-middle-content > div {
  text-align: center;
}

.d-header-bottom,
.d-switch {
  display: flex;
}

.d-logout-text {
  cursor: pointer;
}

.d-tab {
  margin-right: 30px;
  font-family: 'Gotham Bold';
  font-size: 21px;
  color: #151f32;
  position: relative;
  &.d-tab-active > img {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.d-sidebar-title {
  margin-bottom: 20px;
}

.d-sidebar-nav-main,
.d-sidebar-sub-nav {
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
  & > img {
    margin-right: 10px;
  }
}

.d-sidebar-nav-main {
  margin-top: 20px;
}

.d-sidebar-sub-nav {
  padding-left: 15px;
  text-align: right;
}

.d-nav-active {
  position: absolute;
  left: 0;
  top: 31px;
}

.d-footer-title {
  font-family: 'Gotham Bold';
  font-size: 26px;
}

.d-footer-text-main {
  margin-bottom: 10px;
  font-family: 'Red Hat Display';
  font-size: 10px;
}

.d-footer-text {
  margin-bottom: 4px;
  font-family: 'Red Hat Display';
  font-size: 10px;
}

.d-footer-content {
  max-width: 280px;
  margin-left: 120px;
}

.d-widget {
  background: #fff;
  position: relative;
}

.d-header-title {
  color: #e72f88;
  font-family: 'Gotham Bold';
  font-size: 22px;
}

.d-header-subtitle {
  font-family: 'Gotham Light';
  font-size: 19px;
  color: #151f32;
}

.d-score {
  font-size: 55px;
  color: #01b1ea;
  font-weight: 600;
}
.d-score-title {
  font-size: 16px;
  font-weight: 600;
  color: #0f264e;
}
.d-list-item-type {
  font-weight: 500;
}
.d-widget-subtitle {
  font-size: 16px;
  font-weight: 600;
  color: #0f264e;
}
.d-widget-title {
  font-size: 18px;
  font-weight: 600;
  color: #0f264e;
  margin-top: 5px;
}
.d-widget-title-small {
  font-size: 16px;
  font-weight: 600;
  color: #0f264e;
  margin-top: 5px;
}
.d-list-item-title {
  margin-bottom: 5px;
}
.d-list-item-mod {
  color: #0f264e;
  margin-bottom: 30px;
}
.d-score-logo,
.d-sup-logo {
  position: absolute;
  right: 15px;
}
.d-total-score {
  position: relative;
  width: 100%;
  text-align: center;
}
.d-bubble {
  max-width: 100%;
}
.d-total-score-logo {
  position: absolute;
}
.d-score-text {
  position: absolute;
  top: 30%;
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 16px;
}
.d-score-white {
  font-size: 47px;
  color: #fff;
  position: absolute;
  top: 35%;
  font-weight: 600;
  width: 100%;
  text-align: center;
}
.d-total-score-logo {
  position: absolute;
  top: 26%;
  left: 16%;
}
.d-widget-red .d-list-item {
  margin-bottom: 15px;
  margin-top: 15px;
}

.d-widget-sea {
  .d-text {
    margin-bottom: 50px;
  }
  .d-list {
    margin-bottom: 40px;
  }
  .d-list-item {
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.d-widget-blue .d-widget-title {
  margin-bottom: 10px;
}
.d-widget-lightgrey .d-text {
  margin-top: 10px;
}
.d-show-more {
  background: #0b1c3b;
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 5px;
  width: 50%;
}
.d-widget-greyblue {
  .d-widget-list {
    color: #8eb9c6;
    margin-bottom: 20px;
  }
  .d-widget-list-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .d-footnote-center {
    width: 100%;
    position: relative;
    text-align: center;
  }
}

.d-widget-lightgreen {
  .d-text-bold {
    font-size: 18px;
    color: #0f264e;
    font-weight: 600;
  }
  .d-widget-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .d-widget-row-right {
    padding-top: 68px;
    width: 50%;
  }
  .d-text {
    margin-bottom: 10px;
    text-align: justify;
  }
  .d-text-italic {
    font-style: italic;
    margin-bottom: 5px;
  }
}

.d-widget-green {
  .d-green-text {
    color: #b4ca19;
    font-weight: 600;
  }
  .d-widget-list {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .d-widget-list-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 200px;
    margin-bottom: 10px;
  }
  .d-transparent {
    opacity: 0;
  }
  .d-widget-title {
    margin-bottom: 10px;
  }
  .d-widget-content {
    margin-bottom: 45px;
  }
  .d-widget-item-grey {
    color: #9d9fa2;
    font-size: 12px;
    margin-top: 20px;
  }
}

.d-header-menu-mobile {
  display: none;
}
.d-logout-img {
  display: none;
}

.d-mobile-menu-overlay {
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  transition: all 0.2s ease-out;
  margin-left: -100vw;
  padding-top: 40px;
  &.open {
    margin-left: 0;
  }
}

.d-close-button {
  position: absolute;
  font-size: 20px;
  color: #fff;
  right: 15px;
  top: 5px;
}

.d-mobile-menu-item {
  display: flex;
  padding: 15px;
  align-items: center;
  border-bottom: 1px solid #01b1ea;
  color: #fff !important;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    color: #01b1ea !important;
  }
  div {
    margin-right: 15px;
  }
}

.d-mobile-menu-overlay .metismenu-link {
  color: #fff !important;
  &.active,
  &:hover {
    color: #01b1ea !important;
    background: rgba(209, 243, 255, 0.25) !important;
  }
}

@media (max-width: 1500px) {
  .d-sidebar {
    min-width: 280px;
    width: 280px;
  }

  .d-sidebar-base {
    min-width: 280px;
    width: 280px;
  }

  .d-content {
    max-width: calc(100% - 280px);
  }
}

@media (max-width: 1200px) {
  .d-sidebar,
  .d-sidebar-base {
    display: none;
  }
  .d-content {
    max-width: 100%;
  }
  .d-header-top {
    justify-content: center;
  }
  .d-header-menu-mobile {
    z-index: 11;
    display: block;
    margin-left: 16px;
    margin-top: 4px;
    cursor: pointer;
    left: 15px;
    top: 10px;
    font-size: 24px;
  }
  .d-switch,
  .d-switch-text,
  .d-header-print,
  .d-header-settings {
    display: none;
  }
  .d-logout-img {
    display: block;
    font-size: 20px;
    position: absolute;
    right: 15px;
    top: 10px;
  }
  .d-header-top {
    padding-top: 10px;
  }
  .d-footer-content {
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .d-row {
    display: block;
  }
  .d-item-30,
  .d-item-40,
  .d-item-50 {
    width: 100%;
  }
  .d-content {
    padding-right: 15px;
  }
  .d-total-score {
    border: 4px solid #f37b8a;
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .d-footer-content {
    max-width: 100%;
    text-align: center;
  }
  .d-checkbox-padding {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .d-dropzone {
    .col-md-4 {
      padding-top: 15px;
    }
  }
  .d-header {
    font-family: 'Red Hat Display';
  }

  .d-header-top {
    min-height: 1px;
    padding-top: 0;
    justify-content: space-between;
  }

  .d-header-logo {
    height: 20px;
  }

  .d-header {
    padding-top: 10px;
  }
}

@media (max-width: 500px) {
  .d-tab {
    font-size: 16px;
  }

  .d-iqdh-logo {
    display: none;
    //height: 38px;
  }

  .d-header-logo {
    height: 18px;
  }
}
