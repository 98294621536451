//colors
$wine: #5E0B3A;
$pink: #D13A7A;
$grey: #818285;
$text: #464646;

.academy {
  color: $text;
  font-family: 'OpenSans-Light', sans-serif;

  .container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }

  h1 {
    text-transform: uppercase;
    font-size: 55px;
    font-family: 'OpenSans-Light', sans-serif;
    font-weight: bold;
  }

  h2 {
    text-transform: uppercase;
    font-family: 'OpenSans-Light', sans-serif;
    font-weight: 300;
    font-size: 36px;
  }

  h3 {
    text-transform: uppercase;
    font-size: 42px;
    font-weight: bold;
  }

  h4 {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 400;
  }

  .academy__header {
    padding: 22px 20px;
    font-family: 'OpenSans-Light', sans-serif;
    margin-bottom: 64px;
    background-color: $wine;
    color: #fff;
    text-transform: uppercase;
    line-height: 1;

    .container {
      display: flex;
      width: 100%;
      align-items: center;
    }
  }

  .academy__header-logo {
    font-size: 42px;
    color: #fff!important;
  }

  .academy__header-link {
    margin-left: auto;
    font-size: 22px;
    color: #fff;
  }

  .academy__header-logout {
    border: 0;
    font-size: 22px;
    background: transparent;

    color: #fff;
    margin-left: 50px;
  }

  .greeting {
    font-size: 36px;
    margin-bottom: 105px;
    text-transform: uppercase;
  }

  .product-wrapper {
    display: flex;
    margin-top: 64px;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .product {
    text-transform: uppercase;
    color: $text!important;
    position: relative;
    line-height: 1;
    flex: 0 1 45%;
    overflow: hidden;
    border-radius: 10px;
    height: 336px;
    margin-bottom: 64px;
    padding: 200px 25px 15px;
    text-decoration: none;
    min-width: 320px;
    transition: all 0.4s;

    &:hover {
      transform: scale(1.04);
    }
  }

  .product__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: brightness(100%) opacity(0.5);
    background-color: $grey;
  }

  .product__title {
    font-size: 36px;
    font-family: 'OpenSans-Bold', sans-serif;
    z-index: 1;
    position: relative;
  }

  .product__subtitle {
    font-size: 24px;
    z-index: 1;
    position: relative;
  }

  .academy__footer {
    background-color: $grey;
    padding: 40px 20px;
    display: flex;
    margin-top: 80px;
    justify-content: center;
    width: 100%;
    font-size: 28px;
    color: #fff;
    font-family: 'OpenSans-Light', sans-serif;
    text-transform: uppercase;
  }

  .academy__footer-link {
    color: #fff;
    opacity: 0.5;
    text-transform: none;
    margin-left: 40px;
  }

  .product__video {
    border-radius: 10px;
    display: block;
    object-fit: cover;
    max-width: 656px;
    background-color: $grey;
    width: 100%;
  }

  .product-page {
    font-size: 24px;
  }

  .separator {
    background-color: $grey;
    height: 90px;
    width: 100%;
    margin: 80px 0 70px;
    opacity: 0.2;
  }

  .product__author-section {
    display: flex;
    align-items: center;
  }

  .product__author-img {
    width: 172px;
    height: 172px;
    border-radius: 50%;
    background-color: #0ba360;
    object-fit: cover;
    margin-right: 37px;
    border: 0;
  }

  .product__button {
    background: $pink;
    text-align: left;
    z-index: 1;
    border: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    border-radius: 33px;
    text-transform: uppercase;
    font-family: 'OpenSans-Light', sans-serif;
    font-size: 24px;
    padding: 4px 23px;
    width: 100%;
    margin-top: 54px;
  }
}