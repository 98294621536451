.survey-form__answer-input {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.survey-form__question-group {
  margin: 20px 0;
}

.survey-form__question-actions {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.survey-form__question-action {
  margin-left: 10px;
}
