// User Pages

.app-logo {
  height: $logo-height;
  width: $logo-width;
  background: url('~assets/utils/images/logo-sanitas-grey-background.png') no-repeat center;
  background-size: 100%;
}

.app-logo-inverse {
  height: 120px;
  width: 350px;
  background: url('~assets/utils/images/logo-sanitas-grey-background.png') center;
  background-repeat: no-repeat!important;
  background-size: contain!important;
}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: .9;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}
