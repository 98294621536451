@font-face {
  font-family: 'OpenSans';
  font-weight: 300;
  src: url('./fonts/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 600;
  src: url('./fonts/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 600;
  src: url('./fonts/OpenSans-Bold.ttf');
}
@font-face {
  font-family: 'OpenSans';
  font-weight: 400;
  src: url('./fonts/OpenSans-Regular.ttf');
}

body {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Red Hat Display', sans-serif;
  color: #1d1d1d;
}
h1,
h1 span,
h1 p {
  font-size: 60px;
  line-height: 1.4;
  font-weight: 700;
  letter-spacing: 0em;
}
h2,
h2 span,
h2 p {
  font-size: 40px;
  line-height: 1.39;
  font-weight: 600;
  letter-spacing: 0em;
}
h3,
h3 span,
h3 p {
  font-size: 28px;
  line-height: 1.4;
  font-weight: 700;
  letter-spacing: 0em;
}
h4,
h4 span,
h4 p {
  font-size: 22px;
  line-height: 1.4;
  font-weight: 400;
  letter-spacing: -0.01em;
}
h5,
h5 span,
h5 p {
  font-size: 20px;
  line-height: 1.4;
  font-weight: 700;
  letter-spacing: 0em;
  color: #43425d;
}
h6,
h6 span,
h6 p {
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #43425d;
}

.d-subtitle-controls {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .d-video-button {
    height: 38px;
    margin-right: 20px;
  }
}
.css-162g8z5 {
  background-color: #e9ecef !important;
  border: 1px solid #ced4da !important;
  .css-142w6gm {
    color: #495057;
  }
}

.cfc-info-text iframe,
.cfc-info-text video,
.cfc-info-text img {
  max-width: 100%;
  object-fit: cover;
  margin-right: 50px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.cfc-info-text {
  ul {
    display: table;
    padding-inline-start: 20px;
  }
}

.cfc-info-text a {
  color: #e95095;
  transition: color 0.2s;

  &:hover {
    color: #db4990;
  }
}

.cfc-expandable-block {
  display: none;
}

.cfc-expand-button {
  padding: 20px;
  background-color: #d2dede;
  border: pink;
}

.rst__moveHandle.d-last-open-doc {
  background: #0194c4 !important;
  border-color: #0194c4 !important;
  color: #fff;
}

.d-tree .ReactVirtualized__Grid {
  padding: 15px;
}

.d-comment-header {
  color: #ccc;
  font-size: 12px;
}
.d-comment {
  position: relative;
}
.d-comment-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .d-comment-del-btn {
    margin-right: 10px;
  }
}
.d-video-overlay {
  background: rgba(255, 255, 255, 0);
  position: relative;
}
.d-subtitles-language-select {
  min-width: 200px;
  margin-right: 20px;
}
.d-language-subtitles-form {
  padding: 15px 0;
  .divider {
    margin-top: 0;
  }
}
.d-upload-preview {
  border: #ced4da dashed 2px;
  border-radius: 0.3rem;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  &:not(:first-child) {
    margin-top: 35px;
  }
  &:first-child {
    margin-top: 30px;
  }
}
.d-subtitles-form-cell,
.d-sequences-form-cell {
  .form-group {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}
.d-dropzone-sm {
  height: 38px !important;
  padding: 0 15px;
  text-overflow: ellipsis;
  .list-group-item {
    background-color: transparent;
  }
}
.training-video-form-wrapper {
  .input-group-append {
    padding-left: 15px;
  }
}
.card,
.table-responsive,
.card-body {
  overflow: visible;
}
.ReactTable * {
  overflow: visible !important;
}
.d-add-subtitles-button {
  margin-bottom: 15px;
}
.d-subtitles-language-toggle {
  width: 100%;
  border-radius: 4px;
  padding: 7px 15px;
  text-align: left;
  outline: none !important;
  &:focus {
    border-color: #6cdbfe;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}
.d-subtitles-language-toggle-wrapper {
  margin-bottom: 15px;
}

.d-page-title-buttons {
  flex-direction: row !important;
}

.d-top-header-content-right div.dropdown-menu-lg,
.popover {
  z-index: 10001;
}

.fade {
  transition: opacity 0.01s linear;
  -webkit-transition: opacity 0.01s linear;
}

.d-upload-form-group {
  display: flex;
  button {
    margin-left: 10px;
  }
}
.dropzone-wrapper.dropopzone-wrapper-input {
  height: 38px;
  align-items: center;
  display: flex;
  p {
    margin-bottom: 0;
  }
}

.d-header-fixed {
  max-width: 100% !important;
  padding-top: 0 !important;
}

@media (min-width: 1200px) {
  .d-header-fixed {
    position: fixed;
    padding-left: 15px;
    width: calc(100% - 280px) !important;
    right: 0;
    top: 0;
    background: #fff;
    z-index: 3000;
    margin-bottom: 0 !important;
    padding-bottom: 12px;
  }
  .document-page {
    padding-top: 160px;
  }
}

@media (min-width: 1200px) {
  .document-page {
    padding-top: 160px;
  }
  .documents-fixed-header {
    position: fixed;
    top: 0;
    left: 280px;
    /* height: 100px; */
    width: calc(100% - 280px);
    margin-top: 60px;
    padding: 0;
    z-index: 10000;
    background: #fff;
    border-bottom: 1px solid rgba(13, 13, 18, 0.125);
  }
  .documents-fixed-header .app-page-title {
    margin-top: 0px !important;
  }
}

.vjs-error {
  z-index: 0;
}

.font-size-dec2 {
  font-size: calc(100% - 2px);
}
