.navigation__item {
  position: relative;
  margin-top: 10px;
  padding-bottom: 10px;
  margin-right: 40px;

  &:hover {
    @media screen and (min-width: 991px){
      &::before {
        width: calc(100% + 4px)
      }

      .navigation__item-link {
        color: #e95095;
      }
    }
  }
}

.navigation__item--top {
  &::before {
    content: '';
    position: absolute;
    left: -2px;
    height: 2px;
    width: 0;
    bottom: 6px;
    transition: width 0.2s;
    background-color: #e95095;
  }

  &:hover {
    @media screen and (min-width: 991px) {
      &::before {
        width: calc(100% + 4px)
      }
    }
  }
}

.navigation__item-link {
  text-decoration: none!important;
  font-size: 18px;
  color: #43425d;
  transition: all 0.2s;
}

.navigation__dropdown {
  position: absolute;
  top: 35px;
  left: -10px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.075);
  width: max-content;
  background: #fff;

  @media screen and (max-width: 991px) {
    position: relative;
    top: 0;
    box-shadow: none;
  }

  .navigation__item {
    margin-top: 0;
    margin-right: 0;
    padding-bottom: 0;
  }
}

.navigation__dropdown--inner {
  top: 0;
  left: 100%;

  @media screen and (max-width: 991px) {
    position: relative;
    left: 0;
    top: 0
  }
}

.navigation__dropdown-item {
  display: block;
  padding: 8px 12px;
  color: #5e6c79;
  z-index: 1;
  position: relative;
  background: #fff;
  text-decoration: none !important;
  font-size: 16px;
  transition: all 0.2s;

  &:hover {
    background-color: #e95095;
    color: #fff;
  }
}

.navigation__arrow {
  margin-left: auto;
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 100%;
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: #e95095;

  &.is-expanded {
    top: 6px;
  }

  @media screen and (min-width: 991px){
    display: none;
  }
}
