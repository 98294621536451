.content-page {
  max-width: 1040px;
  padding: 0 16px 40px;
  width: 100%;
  margin: 0 auto;
}

.kavedo_player_container {
  width: 100%;
}
