// Accordions

.accordion-wrapper {
  @include border-radius($border-radius);
  border: $gray-200 solid 1px;

  & > .card {
    box-shadow: 0 0 0 0 transparent;

    & > .card-header {
      padding: 1rem;
      height: auto;

      .btn:active,
      .btn:focus,
      .btn:hover {
        text-decoration: none;
      }

      .form-heading {
        p {
          margin: 0;
        }
      }
    }

    .collapse {
      border-bottom: transparent solid 1px;

      &.show {
        border-bottom-color: $gray-200;
      }
    }
  }
}

.accordion-title {
  background-color: #ececec;
  padding: 12px 16px;
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #b2b2b2;
  align-items: center;
  cursor: pointer;

  &:nth-of-type(even) {
    background-color: #fbfbfb;
  }
}
